import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { esMayorDeEdad } from "../../utils/curp";
import {
  checkBlacklistByFieldService,
  checkIfIsNewUserService,
  getCURPDataService,
} from "../../services/application.services";
import { toast } from "react-toastify";
import { CURP } from "@randyd45/curp-validation";

function CurpAutocomplete({
  formData,
  setFormData,
  handlePersonalInfo,
  setShowInfoFields,
  curpFillType,
}) {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wrongCurps, setWrongCurps] = useState([]);

  const handleWrongCurps = (curp) => {
    setWrongCurps((prev) => [...prev, curp]);
  };

  const processCURP = async (curp) => {
    try {
      const canContinue = await checkBlacklist(curp);
      if (canContinue) {
        await getCURPInfo(curp);
      }
    } catch (error) {
      console.error("processCURP error", error);
      toast.error("Error al procesar CURP", { autoClose: 1500 });
      handleWrongCurps(curp);
      handlePersonalInfo("", "curp");
      setLoading(false);
    }
  };

  const checkBlacklist = async (curp) => {
    try {
      const { canContinue } = await checkBlacklistByFieldService("CURP", curp);
      if (!canContinue) {
        handleWrongCurps(curp);
        toast.error(
          "No podemos dar seguimiento a tu solicitud, ya que aplicaste con nosotros hace unos meses.",
          {
            position: "top-center",
            autoClose: 1500,
          }
        );
        if (curpFillType !== "autocompleted") setShowInfoFields(false);
        return false;
      }

      const { isNew } = await checkIfIsNewUserService(curp);
      if (!isNew) {
        handleWrongCurps(curp);
        toast.error(
          "Ya tienes un usuario con nosotros. Por favor, revisa en tu app tus ofertas disponibles. Puedes dar click aquí para abrirla.",
          {
            position: "top-center",
            theme: "light",
            autoClose: 3000,
            onClick: () =>
              (window.location.href = "https://appbeloz.page.link/download"),
          }
        );
        if (curpFillType !== "autocompleted") setShowInfoFields(false);
        return false;
      }
      setFormData({
        ...formData,
        personalInfo: {
          ...formData.personalInfo,
          rfc: curp.substring(0, 10),
        },
      });
      return true;
    } catch (error) {
      console.error("checkBlacklist error", error);
      toast.error("Error al verificar la lista negra", { autoClose: 1500 });
      return false;
    }
  };

  const getCURPInfo = async (curp) => {
    if (curpFillType !== "autocompleted") {
      setShowInfoFields(true);
      return;
    }
    try {
      const { success, data } = await getCURPDataService(curp);
      if (!success) {
        setHasError(true);
        setLoading(false);
        toast.info(
          "Verifica que tu CURP esté bien escrito y rellena la información faltante.",
          {
            position: "top-center",
            autoClose: 4500,
          }
        );
        return;
      }
      const birthday = data.fechaNacimiento.split("/");
      setFormData({
        ...formData,
        personalInfo: {
          firstName: data.nombre,
          lastName1: data.apellidoPaterno,
          lastName2: data.apellidoMaterno,
          day: birthday[0],
          month: birthday[1],
          year: birthday[2],
          curp: curp,
          rfc: curp.substring(0, 10),
        },
      });
    } catch (error) {
      console.error("getCURPInfo error", error);
      toast.info(
        "Verifica que tu CURP esté bien escrito y rellena la información faltante.",
        {
          position: "top-center",
          autoClose: 4500,
        }
      );
    } finally {
      setShowInfoFields(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const curp = formData?.personalInfo?.curp?.toUpperCase() || "";

    if (formData?.personalInfo?.rfc) {
      setShowInfoFields(true);
      setLoading(false);
      return;
    }

    if (curp.length !== 18) return;

    try {
      setLoading(true);
      if (wrongCurps.includes(curp)) {
        handlePersonalInfo("", "curp");
        toast.info(
          "Ya intentamos validar este CURP sin éxito. Por favor, verifica que sea correcto o que seas mayor de edad.",
          { position: "top-center", theme: "light", autoClose: 3000 }
        );
        return;
      }

      const curpObj = new CURP(curp);
      if (!curpObj.isFormatValid()) {
        setHasError(true);
        setLoading(false);
        handleWrongCurps(curp);
        handlePersonalInfo("", "curp");
        toast.error("Formato de CURP inválido.", { autoClose: 1500 });
        return;
      }

      if (!esMayorDeEdad(curp)) {
        setHasError(true);
        handleWrongCurps(curp);
        toast.error("Debes ser mayor de edad", { autoClose: 1500 });
        return;
      }

      setHasError(false);
      processCURP(curp);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [formData?.personalInfo?.curp]);

  return (
    <TextField
      label="CURP *"
      error={formData?.personalInfo?.curp.length !== 18}
      value={formData?.personalInfo?.curp}
      onChange={(e) => handlePersonalInfo(e.target.value, "curp")}
      inputProps={{ maxLength: 18, style: { textTransform: "uppercase" } }}
      helperText={
        hasError
          ? "Verifica que tu CURP esté bien escrito y rellena la información faltante."
          : "Tu CURP debe tener al menos 18 caracteres"
      }
      variant="outlined"
      disabled={loading}
      fullWidth
      style={{ marginBottom: "16px", marginTop: "8px" }}
    />
  );
}

export default CurpAutocomplete;
