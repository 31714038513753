import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Footer, Help, Navbar } from "../components";

const useStyles = makeStyles((theme) => ({
  block: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
  },
}));

export default function TermsAndConditions(props) {
  const classes = useStyles();

  return (
    <section>
      <Navbar />
      <Container maxWidth="sm">
        <Box py={8}>
          <Box mb={4} textAlign="center">
            <Typography variant="overline" component="span">
              Última actualización Abril 4, 2023
            </Typography>
            <Typography variant="h3" component="h2">
              Términos{" "}
              <Typography
                variant="h3"
                component="span"
                style={{ fontWeight: 700, color: "#FACE07" }}
              >
                y condiciones
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              EN EL CASO DE QUE NO ESTÉ DE ACUERDO CON LOS TÉRMINOS Y
              CONDICIONES DE USO DEBE ABSTENERSE DE ACCEDER O UTILIZAR EL
              PORTAL.
              <br />
              <br />
              Los siguientes Términos y Condiciones de Uso son aplicables por el
              simple uso o acceso a la página web (en adelante el "PORTAL") de
              ABDZ COMERZA, S.A. DE C.V. (en adelante "beloz"); por lo que se
              entenderá que el usuario los acepta incondicionalmente, y acuerda
              en obligarse conforme a los mismos. Esto expresamente incluye el
              uso del PORTAL conforme a los términos y condiciones pactados en
              todos aquellos contratos de servicios prestados por beloz.
              <br />
              <br />
              Para efectos de los presentes se entenderá que el usuario es
              aquella persona que realiza el uso o acceso mediante equipo de
              cómputo y/o de comunicación (en adelante el "Usuario")
            </Typography>
            {/* Uso */}
            <Box mt={4} mb={3}>
              <Typography variant="h5">1. USO</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El acceso a o uso del PORTAL conllevan la adhesión plena y sin
              reservas del Usuario a los presentes Términos y Condiciones. A
              través del PORTAL, el Usuario utilizará diversos servicios y
              contenidos (en lo sucesivo, los "Servicios y Contenidos"), puestos
              a disposición de los Usuarios por beloz y/o por terceros
              proveedores de Servicios y Contenidos.
              <br />
              <br />
              Beloz en todo momento tendrá el derecho a negar, restringir o
              condicionar al Usuario el acceso al PORTAL, total o parcialmente,
              a su entera discreción, así como a modificar los Servicios y
              Contenidos del PORTAL en cualquier momento y sin necesidad de
              previo aviso.
              <br />
              <br />
              El Usuario acepta y reconoce que no todos los Servicios y
              Contenidos están disponibles para todos los usuarios y en todas
              las áreas geográficas y que algunos Servicios y Contenidos pueden
              ser utilizados solamente con posterioridad a su contratación y/o
              activación o registro previo por el Usuario y/o mediante el pago
              de una comisión, según se indique en las condiciones de
              contratación que se establezcan en la documentación respectiva.
              <br />
              <br />
              Beloz no garantiza la disponibilidad y continuidad de la operación
              del PORTAL y de los Servicios y Contenidos, ni la utilidad del
              PORTAL o los Servicios y Contenidos en relación con cualquier
              actividad específica, independientemente del medio de acceso que
              utilice el Usuario incluido la telefonía móvil. beloz no será
              responsable por daño o pérdida alguna de cualquier naturaleza que
              pueda ser causado debido a la falta de disponibilidad o
              continuidad de operación del PORTAL y/o de los Servicios y
              Contenidos.
              <br />
              <br />
              El uso y aprovechamiento de los Servicios y Contenidos del PORTAL
              es responsabilidad exclusiva del Usuario, quien en todo caso
              deberá hacer uso de ellos conforme a lo permitido en el propio
              PORTAL y a los usos autorizados en los presentes Términos y
              Condiciones; por lo que el Usuario se obliga a utilizarlos de modo
              tal que no atenten contra las leyes de los Estados Unidos
              Mexicanos y la legislación vigente en el país en que el Usuario se
              encuentre al usarlos, las buenas costumbres y los derechos de
              terceros.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">2. RESTRICCIONES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El PORTAL es para el uso individual del Usuario por lo que no
              podrá comercializar de manera alguna los Servicios y Contenidos.
              <br />
              <br />
              El Usuario no tiene el derecho de colocar híper ligas dentro del
              PORTAL, a utilizar las ligas del PORTAL, ni el derecho de colocar
              o utilizar los Servicios y Contenidos en sitios o páginas propias
              o de terceros sin autorización previa y por escrito de beloz.
              Asimismo, el Usuario no tend rá el derecho de limitar o impedir a
              cualquier otro Usuario el uso del PORTAL.
              <br />
              <br />
              Adicionalmente, el Usuario acepta no utilizar dispositivos,
              software, o cualquier otro medio tendiente a interferir tanto en
              las actividades y/u operaciones del PORTAL o en las bases de datos
              y/o información que se contenga en el mismo.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">3. PROPIEDAD INTELECTUAL</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El Usuario expresamente acepta y reconoce que los derechos de
              propiedad intelectual respecto de los Servicios y Contenidos, así
              como los signos distintivos y dominios de las Páginas y los
              derechos de uso y explotación de los mismos, incluyendo su
              divulgación, publicación, reproducción, distribución y
              transformación, son propiedad exclusiva de beloz.
              <br />
              <br />
              El Usuario expresamente acepta y reconoce que no adquiere ningún
              derecho de propiedad intelectual por el simple uso o acceso a los
              Servicios y Contenidos del PORTAL y que en ningún momento dicho
              uso será considerado como una autorización o licencia para
              utilizar los Servicios y Contenidos con fines distintos a los que
              se contemplan en los presentes Términos y Condiciones y a los
              contratos respectivos.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                4. PROPIEDAD INTELECTUAL DE TERCEROS
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El Usuario acuerda que las disposiciones que se establecen en la
              sección anterior relativa a la titularidad de los derechos de
              beloz también son aplicables a los derechos de terceros respecto
              de los Servicios y Contenidos, dominios o información presentada o
              vinculada al PORTAL.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                5. CALIDAD DE LOS SERVICIOS Y CONTENIDOS
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Ni beloz ni sus proveedores o socios comerciales serán
              responsables de cualquier daño o perjuicio que sufra el Usuario a
              consecuencia de inexactitudes, consultas realizadas, asesorías,
              errores tipográficos y cambios o mejoras que se realicen
              periódicamente a los Servicios y Contenidos. Las recomendaciones y
              consejos obtenidos a través del PORTAL son de naturaleza general,
              por lo que no deben tomarse en cuenta en la adopción de decisiones
              personales ni profesionales. Para ello se debe consultar a un
              profesional apropiado que pueda asesorar al Usuario de acuerdo con
              sus necesidades específicas.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                6. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El hecho de que se ofrezca información en el PORTAL en ningún
              momento implica o conlleva la recomendación, garantía, patrocinio
              o aprobación por parte de beloz respecto de dicha información,
              bienes y/o servicios.
              <br />
              <br />
              La disponibilidad de bienes y/o servicios ofertados por terceros o
              por sitios ligados o vinculados, no es responsabilidad de beloz;
              por lo que éste último no será responsable ante cualquier
              autoridad de cualquier naturaleza, por cualquier asunto
              relacionado con la venta, consumo, distribución, entrega,
              disponibilidad o prestación con respecto de cualquiera de los
              bienes y/o servicios ofertados por terceros o por sitios ligados o
              vinculados a través del PORTAL.
              <br />
              <br />
              Respecto de los Servicios y Contenidos que prestan terceros dentro
              o mediante enlaces (tales como ligas, banners y botones); beloz se
              limita exclusivamente a:
              <br />
              <br />
              (i) informar al Usuario sobre los mismos, y
              <br />
              <br />
              (ii) a proporcionar un medio para poner en contacto al Usuario con
              proveedores o vendedores.
              <br />
              <br />
              Los productos y/o servicios que se comercializan dentro del PORTAL
              y/o en los sitios de terceros enlazados son suministrados por
              comerciantes independientes a beloz por lo que no existe ningún
              tipo de relación laboral, asociación o sociedad, entre beloz y
              dichos terceros. Toda asesoría, consejo, declaración, información
              y contenido de las páginas de terceros enlazadas o dentro del
              PORTAL representan las opiniones y juicios de dicho tercero,
              consecuentemente, el Usuario acepta y reconoce que beloz no será
              responsable de ningún daño o perjuicio que sufra el Usuario como
              consecuencia -directa o indirecta- de los mismos.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">7. CONFIDENCIALIDAD</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              beloz se obliga a mantener confidencial la información que reciba
              del Usuario que tenga dicho carácter conforme a las disposiciones
              legales aplicables, en los Estados Unidos Mexicanos. beloz no
              asume ninguna obligación de mantener confidencial cualquier otra
              información que el Usuario le proporcione, ya sea al inscribirse
              al PORTAL o en cualquier otro momento posterior, incluyendo
              aquella información que el Usuario proporcione a través de
              boletines, pizarras o plática en línea (chats); así como, la
              información que obtenga beloz a través de las Cookies que se
              describen la sección 9.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                8. USO DE LA INFORMACIÓN NO CONFIDENCIAL O INDIVIDUAL
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              INFORMACIÓN NO CONFIDENCIAL O INDIVIDUAL Mediante el uso del
              PORTAL, el Usuario autoriza a beloz a utilizar, publicar,
              reproducir, divulgar, comunicar públicamente y transmitir la
              información no confidencial o no individual proporcionada, en
              términos de lo establecido en el artículo 109 de la Ley Federal de
              los Derechos de Autor y de la fracción I, del artículo 76 bis de
              la Ley Federal de Protección al Consumidor.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">9. COOKIES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El Usuario en su uso o acceso del PORTAL, acepta recibir archivos
              que le transmitan los servidores de beloz.
              <br />
              <br />
              Para efectos de los presentes, "Cookie" significa un archivo de
              datos que se almacena en el disco duro de la computadora del
              Usuario cuando éste tiene acceso al PORTAL. Dichos archivos pueden
              contener información tal como la identificación proporcionada por
              el Usuario o información para rastrear las páginas que el Usuario
              ha visitado. Una Cookie no puede leer los datos o información del
              disco duro del Usuario ni leer las Cookies creadas por otros
              sitios o páginas.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                10. AVISO DE PRIVACIDAD DE DATOS PERSONALES
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              ABDZ COMERZA, S.A. DE C.V. ("beloz"), con domicilio en Avenida
              Popocatépetl 510, Colonia Xoco, Benito Juárez, Benito Juárez,
              03330 Ciudad de México, CDMX, es una sociedad comprometida con la
              protección de datos personales y la privacidad de sus usuarios; en
              cumplimiento a la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares y su Reglamento, así como a los más
              altos estándares en materia de protección de datos y las
              disposiciones financieras y en materia de prevención de lavado de
              dinero aplicables, pone a su disposición el siguiente Aviso de
              Privacidad.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">11. CLAVES DE ACCESO</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El Usuario acepta y reconoce que será el único y exclusivo
              responsable de mantener en secreto las claves de acceso con las
              cuales tenga acceso a ciertos Servicios y Contenidos del PORTAL;
              así como a las páginas de terceros.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">12. MEDIOS ELECTRÓNICOS</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              El uso de las Claves de Acceso, (Claves dinámicas enviadas vía SMS
              al celular o Correo electrónico registrado previamente) que aquí
              se definen será exclusiva responsabilidad del cliente, quien
              reconoce y acepta desde ahora como suyas todas las operaciones que
              se celebren con beloz utilizando dichas Claves de Acceso, y para
              todos los efectos legales a que haya lugar, expresamente también
              reconoce y acepta el carácter personal e intransferible del
              password o clave generada por el cliente, así como su
              confidencialidad.
              <br />
              <br />
              Las Claves de Acceso que se establezcan para el uso de medios
              electrónicos, sustituirán a la firma autógrafa por una de carácter
              electrónico, por lo que las constancias documentales o técnicas
              derivadas del uso de esos medios en donde aparezca dicha firma
              electrónica, producirán los mismos efectos que las leyes otorguen
              a los documentos suscritos por las partes y tendrán igual valor
              probatorio.
              <br />
              <br />
              Las partes reconocen que en términos del artículo 75 fracción XIV
              del Código de Comercio en vigor, los actos relacionados con los
              medios electrónicos aceptados, son de naturaleza mercantil tanto
              para el cliente como para beloz. De acuerdo a lo anterior, el
              Usuario y beloz convienen que:
              <br />
              <br />
              Para efectos de lo previsto en el artículo 89 del Código de
              Comercio en vigor, se entenderá como "mensaje de datos" a toda
              información generada, enviada, recibida archivada o comunicada a
              través de medios electrónicos.
              <br />
              <br />
              Para efectos de lo dispuesto en la fracción I del artículo 90 del
              Código de Comercio en vigor, se entenderá que un "mensaje de
              datos" ha sido enviado por el propio Cliente, cuando éste realice
              operaciones a través del equipo o sistema de que se trate,
              utilizando las Claves de Acceso a las que se refiere este
              clausulado.
              <br />
              <br />
              El Usuario consiente recibir comunicaciones de nuestra parte
              mediante medios electrónicos, y acepta que todos los contratos,
              avisos y otras notificaciones y comunicaciones que le enviemos por
              medios electrónicos satisfacen cualquier requisito de forma
              escrita, salvo que cualquier legislación aplicable con carácter
              imperativo exigirá una forma distinta de comunicación.
              <br />
              <br />
              beloz no será responsable en caso de que no pueda efectuar o
              cumplir con las operaciones electrónicas debido, o en caso
              fortuito y/o fuerza mayor, por desperfectos, caída de sistemas o
              de cómputo, mal funcionamiento o suspensión de los medios de
              comunicación (incluyendo los Medios Electrónicos) o de cualquier
              otro servicio necesario para la prestación del servicio
              respectivo.
              <br />
              <br />
              En caso de que el Usuario no pueda efectuar sus operaciones por
              los motivos aquí señalados, beloz hará su mejor esfuerzo para
              mantener la continuidad en el servicio en el entendido de que no
              garantizará dicha continuidad al Usuario.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                13. TERMINACIÓN DE LOS SERVICIOS
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              beloz se reserva el derecho a negar o dar por terminado el acceso
              o el uso del Usuario al PORTAL, a cualquier servicio, producto o
              material, o cualquier parte de los mismos, bajo la discreción
              total de beloz sin notificación y limitación alguna, salvo
              aquellas estipuladas por el respectivo contrato de servicios, por
              cualquier razón, incluyendo, sin estar limitado al incumplimiento
              por parte del Usuario de cualquier disposición de estas reglas del
              sitio o cualquier contrato de servicios aplicable, o la
              discontinuidad en el acceso de beloz a cualquier producto o
              material. En caso de cualquier terminación por estos motivos,
              beloz no tendrá responsabilidad alguna frente al Usuario.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">14. MODIFICACIONES</Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              beloz se reserva el derecho a revisar los presentes Términos y
              Condiciones en cualquier tiempo, así como para realizar
              modificaciones y adaptarlo modificaciones a la ley, así como a las
              prácticas aplicables del mercado. Asimismo, el Usuario se
              considerará enterado y obligado por los cambios a los Términos y
              Condiciones al momento en que el Usuario ingrese al sitio y haga
              uso de cualesquier materiales, productos o servicios. Dicho uso o
              acceso al sitio por parte del Usuario constituirá la aceptación y
              acuerdo a los cambios a los Términos y Condiciones,
              independientemente de lo dispuesto en los correspondientes
              contratos.
            </Typography>
            <Box mt={4} mb={3}>
              <Typography variant="h5">
                15. LEYES APLICABLES Y JURISDICCIÓN
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              paragraph={true}
              className={classes.block}
            >
              Para la interpretación, cumplimiento y ejecución de los presentes
              Términos y Condiciones, el Usuario está de acuerdo en que serán
              aplicables las leyes Federales de los Estados Unidos Mexicanos y
              competentes los tribunales de la Ciudad de México, renunciando
              expresamente a cualquier otro fuero o jurisdicción que pudiera
              corresponderles en razón de sus domicilios presentes o futuros o
              por cualquier otra causa.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Help />
      <Footer />
    </section>
  );
}
