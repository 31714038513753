import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import { useAnalytics } from "../hooks/useAnalytics";
import { useDynamicLink } from "../hooks/useDynamicLink";

const useStyles = makeStyles((theme) => ({
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  logo: {
    height: 72,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

export default function Download(props) {
  const classes = useStyles();
  const analytics = useAnalytics();
  const downloadAppDynamicLink = useDynamicLink();

  const content = {
    header: "Administra tu préstamo desde la app",
    description:
      "Descarga la aplicación y comienza el proceso de solicitud de manera segura, fácil y rápida.",
    playstore: "./images/download/playstore.png",
    appstore: "./images/download/appstore.png",
    ...props.content,
  };

  return (
    <section style={{ backgroundColor: "rgb(252, 240, 255)" }}>
      <Container maxWidth="md">
        <Box py={8} textAlign="center">
          <Typography
            variant="h3"
            component="h2"
            gutterBottom={true}
            style={{ fontWeight: 700 }}
          >
            {content["header"]}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph={true}>
            {content["description"]}
          </Typography>
          <Box pt={4} display="flex" flexWrap="wrap" justifyContent="center">
            <Link
              href={downloadAppDynamicLink.android}
              onClick={() =>
                analytics.sendEvent({
                  event: "Subscribe",
                  event_category: "DownloadApp",
                })
              }
              target="_blank"
            >
              <img
                src={content["playstore"]}
                alt="Google Play Store"
                className={classes.logo}
              />
            </Link>
            <Link
              href={downloadAppDynamicLink.ios}
              onClick={() =>
                analytics.sendEvent({
                  event: "Subscribe",
                  event_category: "DownloadApp",
                })
              }
              target="_blank"
            >
              <img
                src={content["appstore"]}
                alt="App Store"
                className={classes.logo}
              />
            </Link>
          </Box>
        </Box>
      </Container>
    </section>
  );
}
