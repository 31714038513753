import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export default function Testimonials(props) {
  const classes = useStyles();

  const content = {
    header: "¿Qué dicen de nosotros?",
    "01_image": "./images/testimonials/yair.png",
    "01_name": "Yair",
    "01_job": "Comerciante",
    "01_description":
      "Beloz fue clave para expandir mi comercio. Rápido y confiable, me ofreció el impulso que necesitaba para crecer.",
    "02_image": "./images/testimonials/javier.png",
    "02_name": "Javier",
    "02_job": "Tendero",
    "02_description":
      "Este crédito me permitió invertir en mi negocio, obtener más ganancias y hacer que mi dinero crezca. Me dieron la oportunidad de invertir en mi negocio y en mí.",
    "03_image": "./images/testimonials/alejandro.png",
    "03_name": "Alejandro",
    "03_job": "Estudiante",
    "03_description":
      "Gracias a Beloz, pude financiar mis estudios sin preocupaciones. Su proceso fácil y rápido fue perfecto para mí.",
    ...props.content,
  };

  return (
    <section>
      <Container maxWidth="lg">
        <Box pt={8} pb={10} textAlign="center">
          <Box mb={6}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom={true}
              style={{ fontWeight: 700 }}
            >
              {content["header"]}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} md={4} style={{ marginBottom: 24 }}>
              <Avatar
                alt=""
                src={content["01_image"]}
                className={classes.avatar}
              />
              <Box mb={2}>
                <Typography variant="h6" component="h4" gutterBottom={true}>
                  {content["01_name"]}
                </Typography>
                <Typography variant="body1" color="primary" component="span">
                  {content["01_job"]}
                </Typography>
              </Box>
              <Typography variant="body2" paragraph={true}>
                {content["01_description"]}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: 24 }}>
              <Avatar
                alt=""
                src={content["02_image"]}
                className={classes.avatar}
              />
              <Box mb={2}>
                <Typography variant="h6" component="h4" gutterBottom={true}>
                  {content["02_name"]}
                </Typography>
                <Typography variant="body1" color="primary" component="span">
                  {content["02_job"]}
                </Typography>
              </Box>
              <Typography variant="body2" paragraph={true}>
                {content["02_description"]}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ marginBottom: 24 }}>
              <Avatar
                alt=""
                src={content["03_image"]}
                className={classes.avatar}
              />
              <Box mb={2}>
                <Typography variant="h6" component="h4" gutterBottom={true}>
                  {content["03_name"]}
                </Typography>
                <Typography variant="body1" color="primary" component="span">
                  {content["03_job"]}
                </Typography>
              </Box>
              <Typography variant="body2" paragraph={true}>
                {content["03_description"]}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
