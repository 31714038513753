import { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { animated, useSpring } from "@react-spring/web";
import {
  Acceptance,
  AddressInfo,
  Evaluation,
  Password,
  PersonalInfo,
} from "./sections";
import { Link, Typography, useTheme } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { addressStepIsValid, personalStepIsValid } from "../utils/form";
import { getAllQueryParams } from "../utils/url";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "../hooks/useAnalytics";
import useStyles from "./NewLoan.styles";

const Fade = forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function NewLoanModal({
  open,
  setOpen,
  publicIP,
  curpFillType,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const analytics = useAnalytics();

  const search = useLocation().search;
  const marketing_metadata = getAllQueryParams(search);
  const { referrer = null } = marketing_metadata;

  const [formData, setFormData] = useState({
    personalInfo: {
      firstName: "",
      lastName1: "",
      lastName2: "",
      day: "",
      month: "",
      year: "",
      curp: "",
      rfc: "",
      homoclave: "",
    },
    addressInfo: {
      addressLine1: "",
      extNumber: "",
      addressLine2: "",
      zipCode: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    foundByAnswer: "",
  });
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [attributionChannel, setAttributionChannel] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [amilozBEToken, setAmilozBEToken] = useState(null);
  const [application, setApplication] = useState(null);
  const [evaluated, setEvaluated] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [curpValidated, setCurpValidated] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [disableNextStep, setDisableNextStep] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePersonalInfo = (value, field) => {
    const tempData = { ...formData };
    tempData.personalInfo[field] = value;
    setFormData(tempData);
  };

  const handleNext = () => {
    if (activeStep < 3) {
      analytics.sendEvent({
        event: `AplicaStep2.${activeStep}`,
        event_category: "ButtonClick",
      });
      analytics.sendEvent({
        event: `AplicaStep2.${activeStep}_${curpFillType}`,
        event_category: "ButtonClick",
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleAddressInfo = (value, field) => {
    const tempData = { ...formData };
    tempData.addressInfo[field] = value;
    setFormData(tempData);
  };

  const applicationSteps = [
    {
      label: "Datos personales",
      subtitle:
        "Completa la siguiente información para comenzar con tu solicitud.",
      content: (
        <PersonalInfo
          formData={formData}
          setFormData={setFormData}
          handlePersonalInfo={handlePersonalInfo}
          email={email}
          setEmail={setEmail}
          attributionChannel={attributionChannel}
          setAttributionChannel={setAttributionChannel}
          curpFillType={curpFillType}
          setCurpValidated={setCurpValidated}
        />
      ),
    },
    {
      label: "Dirección",
      subtitle: "La que aparece en tu credencial para votar.",
      content: (
        <AddressInfo
          formData={formData}
          setFormData={setFormData}
          handleAddressInfo={handleAddressInfo}
          stateCode={stateCode}
          setStateCode={setStateCode}
        />
      ),
    },
    // TODO: Enable password
    // {
    //   label: "Contraseña",
    //   subtitle:
    //     "Crea la contraseña que usarás para acceder a la app. Debe de contener al menos 6 caracteres (números y letras).",
    //   content: (
    //     <Password
    //       password={password}
    //       setPassword={setPassword}
    //       setIsValidPassword={setIsValidPassword}
    //     />
    //   ),
    // },
    {
      label: "Autorización",
      subtitle:
        "Enviaremos a tu celular un código para poder empezar tu solicitud.",
      content: (
        <Acceptance
          formData={formData}
          handleNext={handleNext}
          amilozBEToken={amilozBEToken}
          setAmilozBEToken={setAmilozBEToken}
          marketing_metadata={marketing_metadata}
          email={email}
          phone={phone}
          setPhone={setPhone}
          attributionChannel={attributionChannel}
          setApplication={setApplication}
          publicIP={publicIP}
          referrer={referrer}
          curpFillType={curpFillType}
          password={password}
        />
      ),
    },
    {
      label: "Evaluación",
      subtitle: "Espera un poco, estamos evaluando tu solicitud.",
      content: (
        <Evaluation
          amilozBEToken={amilozBEToken}
          application={application}
          formData={formData}
          email={email}
          phone={phone}
          publicIP={publicIP}
          evaluated={evaluated}
          setEvaluated={setEvaluated}
          isApproved={isApproved}
          setIsApproved={setIsApproved}
          isPending={isPending}
          setIsPending={setIsPending}
        />
      ),
    },
  ];

  const handleDisableNextStep = () => {
    switch (activeStep) {
      case 0:
        setDisableNextStep(
          !curpValidated ||
            !personalStepIsValid(
              formData?.personalInfo,
              email,
              attributionChannel
            )
        );
        break;
      case 1:
        setDisableNextStep(!addressStepIsValid(formData?.addressInfo));
        break;
      case 2:
        setDisableNextStep(!isValidPassword);
        break;
    }
  };

  useEffect(() => {
    handleDisableNextStep();
  }, [
    formData,
    email,
    attributionChannel,
    activeStep,
    curpValidated,
    isValidPassword,
  ]);

  useEffect(() => {
    setCurpValidated(curpFillType === "autogenerated" ? false : true);
  }, [curpFillType]);

  const maxSteps = applicationSteps.length;
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.stepTextContainer}>
            {applicationSteps.map((step, index) => (
              <Typography
                key={index}
                variant="body2"
                component="p"
                className={classes.stepText}
                onClick={() => {
                  if (index <= activeStep) {
                    setActiveStep(index);
                  }
                }}
                style={{
                  cursor: index <= activeStep ? "pointer" : "default",
                  color: index <= activeStep ? "#FACE07" : "#C4C4C4",
                  fontSize: "0.5rem",
                }}
              >
                {step.label}
              </Typography>
            ))}
          </div>

          <Typography variant="body2" component="h6">
            {applicationSteps[activeStep].subtitle}
          </Typography>
          <section className={classes.contentContainer}>
            {applicationSteps[activeStep].content}
          </section>
          <Typography
            variant="body2"
            component="p"
            style={{ textAlign: "center" }}
          >
            ¿Tienes problemas? Contacta a uno de nuestos agentes por{" "}
            <Link
              variant="body2"
              href="https://wa.me/+5215592251996/?text=Necesito+ayuda+con+el+proceso+de+solicitud+desde+la+pagina+web"
              target="_blank"
              className={classes.contactLink}
            >
              whatsapp
            </Link>
          </Typography>
          <div className={classes.progressContainer}>
            <div className={classes.progressBar}>
              <div
                className={classes.progressBarInner}
                style={{ width: `${((activeStep + 1) / maxSteps) * 100}%` }}
              />
              <div className={classes.progressTextContainer}>
                <Typography variant="body2" component="p">
                  {activeStep + 1}
                </Typography>
                <Typography variant="body2" component="p">
                  {maxSteps}
                </Typography>
              </div>
            </div>
            <button
              disabled={disableNextStep || activeStep === maxSteps - 1}
              className={
                disableNextStep || activeStep === maxSteps - 1
                  ? classes.disabledNavButton
                  : classes.navButton
              }
              onClick={handleNext}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft style={{ color: "#FFF" }} />
              ) : (
                <KeyboardArrowRight style={{ color: "#FFF" }} />
              )}
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
