import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

const useStyles = makeStyles((theme) => ({
  primaryAction: {
    backgroundColor: "black",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#FACE07",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  contentBox: {
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    backgroundColor: "white",
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(2),
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "contain",
    height: 512,
    width: "100%",
  },
  bannerInfo: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  bannerParagraph: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

export default function Header({ handleOpen }) {
  const classes = useStyles();

  const content = {
    header: "¡Recibe tu préstamo en",
    header2: "menos de 48 hrs!*",
    description: "* Sujeto a evaluación de historial crediticio",
    "primary-action": "Aplicar",
    image: "./images/banner/beloz.png",
  };

  return (
    <section
      style={{
        backgroundColor: "black",
        backgroundImage: 'url("./images/banner/background.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        color: "white",
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Box className={classes.contentBox}>
            <Container>
              <Typography
                variant="h3"
                component="h1"
                gutterBottom={false}
                style={{ fontWeight: 500, color: "black" }}
              >
                {content["header"]}{" "}
                <Typography
                  variant="h3"
                  component="span"
                  gutterBottom={false}
                  style={{ fontWeight: 700, color: "#FACE07" }}
                >
                  {content["header2"]}
                </Typography>
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                paragraph={true}
              >
                {content["description"]}
              </Typography>
              <Box
                my={5}
                style={{
                  padding: 16,
                  color: "black",
                  backgroundColor: "rgb(255, 253, 235)",
                  borderRadius: 16,
                }}
                className={classes.bannerInfo}
              >
                <section>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500, textAlign: "center" }}
                  >
                    Consigue un préstamo de
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: 700, textAlign: "center" }}
                  >
                    $750 mxn a 0% interés
                  </Typography>
                </section>
                <section className={classes.bannerParagraph}>
                  <MonetizationOnIcon />
                  <Typography variant="body1">
                    Plazos flexibles y personalizables
                  </Typography>
                </section>
                <section className={classes.bannerParagraph}>
                  <MonetizationOnIcon />
                  <Typography variant="body1">
                    Monto por miembro del grupo
                  </Typography>
                </section>
                <section className={classes.bannerParagraph}>
                  <MonetizationOnIcon />
                  <Typography variant="body1">
                    Después podrás renovar con mayores montos
                  </Typography>
                </section>
              </Box>
              <Box mt={4}>
                <Button
                  id="aplicar-header"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.primaryAction}
                  onClick={handleOpen}
                >
                  {content["primary-action"]}
                </Button>
              </Box>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography
            variant="h3"
            component="h1"
            color="inherit"
            gutterBottom={true}
            style={{ textAlign: "center", fontWeight: 500 }}
          >
            Impulsa el crecimiento de tu negocio con Beloz
          </Typography>
          <Box position="relative" height={512}>
            <img
              className={classes.img}
              src={content["image"]}
              alt="Beloz Banner"
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
