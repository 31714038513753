import { useLocation } from "react-router-dom";
import { defaultUTMParams, getAllQueryParams } from "../utils/url";
import { capitalizeFirstLetter } from "../utils/string";

export const useAnalytics = () => {
  const queryParams = useLocation().search || `?${defaultUTMParams}`;
  const marketingMetadata = getAllQueryParams(queryParams);

  const sendEventsWithSocialName = ({ event, marketingMetadata, gtagBody }) => {
    const capitalizedSource = capitalizeFirstLetter(marketingMetadata?.source);
    const eventWithSocialName = `${event}${capitalizedSource}`;

    fbq('track', eventWithSocialName, marketingMetadata);
    gtag('event', eventWithSocialName, gtagBody);
    ttq.track(eventWithSocialName, marketingMetadata);
  }

  const sendEvent = ({ event, event_category, additional_params = {} }) => {
    const gtagBody = {
      ...additional_params,
      'event_category': event_category,
    }

    if (marketingMetadata?.channel) {
      gtagBody['channel'] = marketingMetadata.channel;
    }

    if (marketingMetadata?.source) {
      gtagBody['source'] = marketingMetadata.source;
    }

    if (marketingMetadata?.account) {
      gtagBody['account'] = marketingMetadata.account;
    }

    if (marketingMetadata?.landing) {
      gtagBody['landing'] = marketingMetadata.landing;
    }

    if (marketingMetadata?.campaign) {
      gtagBody['campaign'] = marketingMetadata.campaign;
    }

    fbq('track', event, marketingMetadata);
    gtag('event', event, gtagBody);
    ttq.track(event, marketingMetadata);

    if (event === 'Subscribe') {
      gtag('event', 'conversion', { 'send_to': 'AW-11191530060/BB2qCOPppqQYEMzkxNgp' });
    }

    if (event === 'IniciateCheckout') {
      gtag('event', 'conversion', { 'send_to': 'AW-11191530060/gnUDCMPwpqQYEMzkxNgp' });
    }

    if (event === 'Contact') {
      gtag('event', 'conversion', { 'send_to': 'AW-11191530060/ezysCMDwpqQYEMzkxNgp' });
    }

    if (marketingMetadata?.source) {
      sendEventsWithSocialName({ event, marketingMetadata, gtagBody });
    }
  }

  return {
    sendEvent
  }
};