import { Box, Container, Grid, Link, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 36,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    filter: "grayscale(100%)",
  },
}));

function PoweredBy() {
  const classes = useStyles();
  return (
    <section>
      <Container maxWidth="lg">
        <Box py={3}>
          <Grid container>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://www.arcusfi.com" target="_blank">
                <img
                  src="/images/poweredBy/arcus.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://www.circulodecredito.com.mx" target="_blank">
                <img
                  src="/images/poweredBy/circulo.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://www.legalario.com" target="_blank">
                <img
                  src="/images/poweredBy/legalario.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://www.metamap.com" target="_blank">
                <img
                  src="/images/poweredBy/metamap.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://nubarium.com" target="_blank">
                <img
                  src="/images/poweredBy/nubarium.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <Link href="https://www.openpay.mx" target="_blank">
                <img
                  src="/images/poweredBy/openpay.png"
                  alt=""
                  className={classes.logo}
                />
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}

export default PoweredBy;
