export const ATTRIBUTION_CHANNELS = [
  {
    value: "facebook",
    label: "Anuncio de Facebook",
  },
  {
    value: "tiktok",
    label: "Anuncio de TikTok",
  },
  {
    value: "google",
    label: "Anuncio de Google",
  },
  {
    value: "recomendacion",
    label: "Me la recomendarion",
  },
  {
    value: "comentario",
    label: "Comentarios en grupos de Facebook",
  },
  {
    value: "buscador",
    label: "Desde un buscador (ejemplo: Google, Yahoo, Bing)",
  },
];

export const ARCUS_3_LETTER = {
  AGUASCALIENTES: "AGU",
  "BAJA CALIFORNIA NORTE": "BCN",
  "BAJA CALIFORNIA": "BCN",
  "BAJA CALIFORNIA SUR": "BCS",
  CAMPECHE: "CAM",
  "CIUDAD DE MÉXICO": "CMX",
  CHIAPAS: "CHP",
  CHIHUAHUA: "CHH",
  COAHUILA: "COA",
  "COAHUILA DE ZARAGOZA": "COA",
  COLIMA: "COL",
  DURANGO: "DUR",
  GUANAJUATO: "GUA",
  GUERRERO: "GRO",
  HIDALGO: "HID",
  JALISCO: "JAL",
  MÉXICO: "MEX",
  MICHOACÁN: "MIC",
  "MICHOCÁN DE OCAMPO": "MIC",
  MORELOS: "MOR",
  NAYARIT: "NAY",
  "NUEVO LEÓN": "NLE",
  OAXACA: "OAX",
  PUEBLA: "PUE",
  QUERÉTARO: "QUE",
  "QUINTANA ROO": "ROO",
  "SAN LUIS POTOSÍ": "SLP",
  SINALOA: "SIN",
  SONORA: "SON",
  TABASCO: "TAB",
  TAMAULIPAS: "TAM",
  TLAXCALA: "TLA",
  VERACRUZ: "VER",
  "VERACRUZ DE IGNACIO DE LA LLAVE": "VER",
  YUCATÁN: "YUC",
  ZACATECAS: "ZAC",
};

export const STATE_LETTERS = {
  AGUASCALIENTES: "AGS",
  "BAJA CALIFORNIA NORTE": "BC",
  "BAJA CALIFORNIA": "BC",
  "BAJA CALIFORNIA SUR": "BCS",
  CAMPECHE: "CAMP",
  "CIUDAD DE MÉXICO": "CDMX",
  CHIAPAS: "CHIS",
  CHIHUAHUA: "CHIH",
  COAHUILA: "COAH",
  "COAHUILA DE ZARAGOZA": "COAH",
  COLIMA: "COL",
  DURANGO: "DGO",
  GUANAJUATO: "GTO",
  GUERRERO: "GRO",
  HIDALGO: "HGO",
  JALISCO: "JAL",
  MÉXICO: "MEX",
  MICHOACÁN: "MICH",
  "MICHOACÁN DE OCAMPO": "MICH",
  MORELOS: "MOR",
  NAYARIT: "NAY",
  "NUEVO LEÓN": "NL",
  OAXACA: "OAX",
  PUEBLA: "PUE",
  QUERÉTARO: "QRO",
  "QUINTANA ROO": "QROO",
  "SAN LUIS POTOSÍ": "SLP",
  SINALOA: "SIN",
  SONORA: "SON",
  TABASCO: "TAB",
  TAMAULIPAS: "TAMP",
  TLAXCALA: "TLAX",
  VERACRUZ: "VER",
  "VERACRUZ DE IGNACIO DE LA LLAVE": "VER",
  YUCATÁN: "YUC",
  ZACATECAS: "ZAC",
};

export const LUGAR_DE_NACIMIENTO = [
  { label: "Aguascalientes", value: "AS" },
  { label: "Baja California", value: "BC" },
  { label: "Baja California Sur", value: "BS" },
  { label: "Campeche", value: "CC" },
  { label: "Coahuila", value: "CL" },
  { label: "Colima", value: "CM" },
  { label: "Chiapas", value: "CS" },
  { label: "Chihuahua", value: "CH" },
  { label: "Distrito Federal / Ciudad de México", value: "DF" },
  { label: "Durango", value: "DG" },
  { label: "Guanajuato", value: "GT" },
  { label: "Guerrero", value: "GR" },
  { label: "Hidalgo", value: "HG" },
  { label: "Jalisco", value: "JC" },
  { label: "Estado de México", value: "MC" },
  { label: "Michoacán", value: "MN" },
  { label: "Morelos", value: "MS" },
  { label: "Nayarit", value: "NT" },
  { label: "Nuevo León", value: "NL" },
  { label: "Oaxaca", value: "OC" },
  { label: "Puebla", value: "PL" },
  { label: "Querétaro", value: "QT" },
  { label: "Quintana Roo", value: "QR" },
  { label: "San Luis Potosí", value: "SP" },
  { label: "Sinaloa", value: "SL" },
  { label: "Sonora", value: "SR" },
  { label: "Tabasco", value: "TC" },
  { label: "Tamaulipas", value: "TS" },
  { label: "Tlaxcala", value: "TL" },
  { label: "Veracruz", value: "VZ" },
  { label: "Yucatán", value: "YN" },
  { label: "Zacatecas", value: "ZS" },
  { label: "Nacido en el Extranjero", value: "NE" },
];

export const VALIDATION_RULES = [
  {
    rule: "atLeast6Chars",
    message: "Contiene al menos 6 carácteres",
  },
  {
    rule: "atLeast1Number",
    message: "Contiene al menos 1 número",
  },
  {
    rule: "passwordMatch",
    message: "Las contraseñas coinciden",
  },
];
