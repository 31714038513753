export const personalStepIsValid = (
  personalInfo,
  email,
  attributionChannel
) => {
  if (!attributionChannel || !email) {
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailRegex.test(email)) {
    return false;
  }

  if (!personalInfo) {
    return false;
  }

  const { firstName, lastName1, lastName2, day, month, year, curp, rfc } =
    personalInfo;

  if (
    !firstName ||
    !lastName1 ||
    !lastName2 ||
    !day ||
    !month ||
    !year ||
    !curp ||
    !rfc
  ) {
    return false;
  }

  if (curp.length < 18 || rfc.length !== 10) {
    return false;
  }

  return true;
};

export const addressStepIsValid = (addressInfo) => {
  if (!addressInfo) {
    return false;
  }

  const { addressLine1, extNumber, zipCode, neighborhood, city, state } =
    addressInfo;

  if (
    !addressLine1 ||
    !zipCode ||
    !neighborhood ||
    !city ||
    !state ||
    !extNumber
  ) {
    return false;
  }

  return true;
};
