import axios from "axios";

const belozV2BE = axios.create({
  baseURL: import.meta.env.VITE_APP_UNDERWRITING_ENDPOINT,
  timeout: parseInt(import.meta.env.VITE_APP_BACKEND_TIMEOUT),
});

export const createUnderwritingApplication = async (body) => {
  try {
    const { data, status } = await belozV2BE.post("/orchestrator", body);
    if (![200, 201].includes(status)) {
      return {
        success: false,
        data: null,
      };
    }

    return {
      success: true,
      data: data?.data,
    };
  } catch (e) {
    console.log("createUnderwritingApplication", e);
    return {
      success: false,
    };
  }
};

export const createReprocessUnderwriting = async (
  userId,
  applicationId,
  requestBody
) => {
  try {
    const { data } = await belozV2BE.post("/orchestrator/reprocess", {
      userId,
      applicationId,
      requestBody,
    });

    return {
      success: data?.success,
    };
  } catch (e) {
    console.log("createReprocessUnderwriting", e);
    return {
      success: false,
    };
  }
};

export const updateReprocessUnderwriting = async (
  userId,
  applicationId,
  processed
) => {
  try {
    const { data } = await belozV2BE.put("/orchestrator/reprocess", {
      userId,
      applicationId,
      processed,
    });

    return {
      success: data?.success,
    };
  } catch (e) {
    console.log("updateReprocessUnderwriting", e);
    return {
      success: false,
    };
  }
};
