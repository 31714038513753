import { useState } from "react";
import { TextField } from "@material-ui/core";
import { AddressAutocomplete } from "../helpers";
import useStyles from "../NewLoan.styles";

function AddressInfo({ formData, setFormData, handleAddressInfo }) {
  const [showAddressFields, setShowAddressFields] = useState(false);
  const classes = useStyles();

  return (
    <section>
      <AddressAutocomplete
        formData={formData}
        setFormData={setFormData}
        handleAddressInfo={handleAddressInfo}
        setShowAddressFields={setShowAddressFields}
      />
      {showAddressFields && (
        <section className={classes.addressSection}>
          <TextField
            label="Delegación / Municipio *"
            value={formData?.addressInfo?.city}
            onChange={(e) => handleAddressInfo(e.target.value, "city")}
            inputProps={{
              className: classes.textFieldUppercase,
            }}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Estado *"
            value={formData?.addressInfo?.state}
            onChange={(e) => handleAddressInfo(e.target.value, "state")}
            inputProps={{
              className: classes.textFieldUppercase,
            }}
            variant="outlined"
            fullWidth
          />
          <TextField
            label="Calle *"
            value={formData?.addressInfo?.addressLine1}
            onChange={(e) => handleAddressInfo(e.target.value, "addressLine1")}
            inputProps={{
              className: classes.textFieldUppercase,
            }}
            variant="outlined"
            fullWidth
            className={classes.root}
          />
          <section style={{ display: "flex", width: "100%" }}>
            <TextField
              label="Num. exterior *"
              value={formData?.addressInfo?.extNumber}
              onChange={(e) => handleAddressInfo(e.target.value, "extNumber")}
              inputProps={{
                className: classes.textFieldUppercase,
              }}
              variant="outlined"
              className={`${classes.root} ${classes.halfWidthLeft}`}
            />
            <TextField
              label="Num. interior"
              value={formData?.addressInfo?.addressLine2}
              helperText="Opcional"
              onChange={(e) =>
                handleAddressInfo(e.target.value, "addressLine2")
              }
              inputProps={{
                className: classes.textFieldUppercase,
              }}
              variant="outlined"
              className={`${classes.halfWidthRight}`}
            />
          </section>
        </section>
      )}
    </section>
  );
}

export default AddressInfo;
