export const validaFormatoCURP = (curp) => {
  var regex = /[A-Za-z]{4}\d{6}[A-Za-z0-9]{6}[A-Za-z0-9]{2}/g;
  return regex.test(curp);
};

export const esMayorDeEdad = (curp) => {
  var fechaNacimiento = curp.substring(4, 10);
  var año = parseInt(fechaNacimiento.substring(0, 2), 10);
  var mes = parseInt(fechaNacimiento.substring(2, 4), 10) - 1;
  var dia = parseInt(fechaNacimiento.substring(4, 6), 10);

  var añoCompleto = año + (año > 20 ? 1900 : 2000);

  var fecha18Años = new Date(añoCompleto + 18, mes, dia);
  var fechaActual = new Date();

  return fechaActual >= fecha18Años;
};

export const primeraLetraYVocalInterna = (palabra) => {
  palabra = palabra.toUpperCase();
  let primeraLetra = palabra.charAt(0);
  let primeraVocalInterna = "";
  const vocales = /[AEIOU]/;

  for (let i = 1; i < palabra.length; i++) {
    if (vocales.test(palabra[i])) {
      primeraVocalInterna = palabra[i];
      break;
    }
  }

  return primeraLetra + primeraVocalInterna;
};

export const primeraConsonanteInterna = (palabra) => {
  palabra = palabra.toUpperCase();
  palabra = palabra.replace(/Ñ/g, "N");
  const vocales = /[AEIOU]/;
  let primeraConsonante = "";

  let posicionPrimeraVocal = -1;
  for (let i = 0; i < palabra.length; i++) {
    if (vocales.test(palabra[i])) {
      posicionPrimeraVocal = i;
      break;
    }
  }

  if (posicionPrimeraVocal !== -1) {
    for (let i = posicionPrimeraVocal + 1; i < palabra.length; i++) {
      if (!vocales.test(palabra[i])) {
        primeraConsonante = palabra[i];
        break;
      }
    }
  }

  return primeraConsonante;
};

export const generarIdentificadores = (
  personalInfo,
  sex,
  curpCode,
  birthPlace,
  handlePersonalInfo,
  setRawCURP
) => {
  const { firstName, lastName1, lastName2, day, month, year } = personalInfo;
  let F2LAVLN1 = "",
    FLLN2 = "",
    FLFN = "",
    Y2D = "",
    M2D = "",
    D2D = "",
    FCLN1 = "",
    FCLN2 = "",
    FCFN = "";

  if (lastName1.length >= 2) {
    F2LAVLN1 = primeraLetraYVocalInterna(lastName1);
    FCLN1 = lastName1.length === 2 ? "X" : primeraConsonanteInterna(lastName1);
  }
  if (lastName2.length >= 2) {
    FLLN2 = lastName2.slice(0, 1).toUpperCase();
    FCLN2 = lastName2.length === 2 ? "X" : primeraConsonanteInterna(lastName2);
  }
  if (firstName.length >= 2) {
    FLFN = firstName.slice(0, 1).toUpperCase();
    FCFN = firstName.length === 2 ? "X" : primeraConsonanteInterna(firstName);
  }
  if (year.length === 4) {
    Y2D = year.slice(2, 4);
  }
  if (month.length > 0) {
    M2D = month.length === 2 ? month : `0${month}`;
  }
  if (day.length > 0) {
    D2D = day.length === 2 ? day : `0${day}`;
  }

  const RFC = `${F2LAVLN1}${FLLN2}${FLFN}${Y2D}${M2D}${D2D}`;
  const RAW_CURP = `${F2LAVLN1}${FLLN2}${FLFN}${Y2D}${M2D}${D2D}${sex}${birthPlace}${FCLN1}${FCLN2}${FCFN}`;
  const CURP = `${F2LAVLN1}${FLLN2}${FLFN}${Y2D}${M2D}${D2D}${sex}${birthPlace}${FCLN1}${FCLN2}${FCFN}${curpCode}`;

  if (personalInfo.rfc === "" && RFC.length === 10) {
    handlePersonalInfo(RFC, "rfc");
  }
  if (RAW_CURP.length === 16) {
    setRawCURP(RAW_CURP);
  }
  if (personalInfo.curp === "" && CURP.length === 18) {
    handlePersonalInfo(CURP, "curp");
  }
};
