import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  faqImage: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  faqImageMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

function FAQ() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <section style={{ backgroundColor: "rgb(242, 242, 242)" }}>
      <Container maxWidth="lg">
        <Box py={6}>
          <Box textAlign="center" mb={4}>
            <Container maxWidth="sm">
              <Typography
                variant="h3"
                component="h2"
                gutterBottom={true}
                style={{ fontWeight: 700 }}
              >
                Preguntas frecuentes
              </Typography>
            </Container>
          </Box>
          <section
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Quiénes somos?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  Beloz es una empresa legalmente constituida en México y
                  completamente digital. Nuestros trámites son 100% seguros.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Qué hace Beloz?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  Beloz te ofrece préstamos grupales, comenzando por una oferta
                  única de $750 mxn a 0% de interés, y posteriormente podrás
                  renovar con plazos y beneficios variados para que crezcas con
                  nosotros.
                  <br />
                  <br />
                  El proceso de aplicación es 100% digital y toma sólo unos
                  minutos, estará sujeto a la aprobación de tu crédito.
                  <br />
                  <br />
                  No pedimos anticipos y tenemos cobertura en toda la República
                  Mexicana.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Dónde se ubican?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  Nuestro domicilio fiscal está en Avenida Popocatépetl 510, CP.
                  03330, Benito Juárez, Ciudad de México, México.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Cuáles son los requisitos?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" component="span">
                  <ol>
                    <li>Ser mayor de edad.</li>
                    <li>Tener a la mano tu INE por ambos lados.</li>
                    <li>CURP.</li>
                    <li>Comprobante de domicilio (no mayor a 3 meses).</li>
                    <li>
                      Tener una cuenta bancaria con CLABE (donde recibirás tu
                      dinero).
                    </li>
                    <li>Tener buen historial crediticio.</li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Qué medios de pago aceptan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" component="span">
                  <li>Podrás pagar por transferencia electrónica (SPEI).</li>
                  <li>Podrás depositar en una cuenta de Banco Azteca.</li>
                  <li>
                    Podrás pagar en efectivo en distintas sucursales aliadas de
                    Open Pay.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography className={classes.heading}>
                  ¿Cómo puedo contactarlos?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" component="span">
                  Por medio de los siguientes medios:
                  <br />
                  <br />
                  <li>
                    <strong>Teléfono:</strong> +52 5592251996
                  </li>
                  <li>
                    <strong>Email:</strong> soporte@amiloz.com
                  </li>
                  <li>
                    <strong>Dirección:</strong> Avenida Popocatépetl 510, cp.
                    03330, Benito Juárez, Ciudad de México, México.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </section>
        </Box>
      </Container>
    </section>
  );
}

export default FAQ;
