import { useEffect, useState } from "react";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { toast } from "react-toastify";

import {
  esMayorDeEdad,
  generarIdentificadores,
  validaFormatoCURP,
} from "../../utils/curp";
import { LUGAR_DE_NACIMIENTO } from "../../utils/constants";
import {
  checkBlacklistByFieldService,
  checkIfIsNewUserService,
} from "../../services/application.services";

const useStyles = makeStyles((theme) => ({
  customTextField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FACE07",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FACE07",
      },
    },
    "& .MuiFormLabel-root": {
      color: "#000000",
    },
  },
}));
function CurpAutogenerated({
  formData,
  handlePersonalInfo,
  showInfoFields,
  setShowInfoFields,
  setCurpValidated,
}) {
  const classes = useStyles();

  const [rawCURP, setRawCURP] = useState("");
  const [curpCode, setCurpCode] = useState("");
  const [sex, setSex] = useState("");
  const [birthPlace, setBirthPlace] = useState("");

  const handleChange = (event) => {
    handlePersonalInfo("", "curp");
    setBirthPlace(event.target.value);
  };

  const processCURP = async (curp) => {
    try {
      const canContinue = await checkBlacklist(curp);
      if (canContinue) {
        setCurpValidated(true);
      }
    } catch (error) {
      console.log("processCURP error", error);
      handlePersonalInfo("", "curp");
      setLoading(false);
    }
  };

  const checkBlacklist = async (curp) => {
    try {
      const { canContinue } = await checkBlacklistByFieldService("CURP", curp);
      if (!canContinue) {
        toast.error(
          "No podemos dar seguimiento a tu solicitud, ya que aplicaste con nosotros hace unos meses.",
          {
            position: "top-center",
            theme: "light",
            autoClose: true,
          }
        );
        // TODO
        if (curpFillType !== "autocompleted") setShowInfoFields(false);
        return false;
      }

      const { isNew } = await checkIfIsNewUserService(curp);
      if (!isNew) {
        toast.error(
          "Ya tienes un usuario con nosotros. Por favor, revisa en tu app tus ofertas disponibles. Puedes dar click aquí para abrirla.",
          {
            position: "top-center",
            theme: "light",
            autoClose: true,
            onClick: () =>
              (window.location.href = "https://appbeloz.page.link/download"),
          }
        );
        // TODO
        if (curpFillType !== "autocompleted") setShowInfoFields(false);
        return false;
      }
      return true;
    } catch (error) {
      console.log("checkBlacklist error", error);
      return false;
    }
  };

  useEffect(() => {
    generarIdentificadores(
      formData.personalInfo,
      sex,
      curpCode,
      birthPlace,
      handlePersonalInfo,
      setRawCURP
    );
    if (formData.personalInfo.curp.length === 18) {
      if (!validaFormatoCURP(formData.personalInfo.curp)) {
        toast.error("Revisa que tus datos sean correctos.", {
          position: "top-center",
          theme: "light",
          autoClose: true,
        });
        setCurpCode("");
      } else {
        if (!esMayorDeEdad(formData.personalInfo.curp)) {
          toast.error("Debes ser mayor de edad para aplicar con nosotros.", {
            position: "top-center",
            theme: "light",
            autoClose: true,
          });
          setShowInfoFields(false);
        } else {
          processCURP(formData.personalInfo.curp);
        }
      }
    }
  }, [formData, sex, curpCode, birthPlace, handlePersonalInfo, setRawCURP]);

  return (
    <>
      <Typography variant="caption">Sexo:</Typography>
      <RadioGroup row style={{ alignSelf: "center" }}>
        <FormControlLabel
          value="H"
          disabled={!showInfoFields}
          control={
            <Radio
              color="primary"
              onClick={(e) => {
                handlePersonalInfo("", "curp");
                setSex(e.target.value);
              }}
            />
          }
          label="Hombre"
        />
        <FormControlLabel
          value="M"
          disabled={!showInfoFields}
          control={
            <Radio
              color="primary"
              onClick={(e) => {
                handlePersonalInfo("", "curp");
                setSex(e.target.value);
              }}
            />
          }
          label="Mujer"
        />
      </RadioGroup>
      <Typography variant="caption">Lugar de nacimiento:</Typography>
      <TextField
        select
        label="Lugar de nacimiento"
        value={birthPlace}
        onChange={handleChange}
        variant="outlined"
        disabled={!showInfoFields}
      >
        {LUGAR_DE_NACIMIENTO.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <div>
        <TextField
          label="CURP *"
          disabled
          value={rawCURP}
          helperText="Verifica que sea correcto y completa los últimos 2 dígitos"
          inputProps={{
            maxLength: 16,
            style: { textTransform: "uppercase" },
          }}
          variant="outlined"
          style={{ width: "69%" }}
        />
        <TextField
          label="Homoclave"
          placeholder="Ej. 00, 0A, XX"
          error={curpCode.length !== 2}
          value={curpCode}
          onChange={(e) => {
            handlePersonalInfo("", "curp");
            setCurpCode(e.target.value);
          }}
          inputProps={{
            maxLength: 2,
            style: { textTransform: "uppercase" },
          }}
          variant="outlined"
          disabled={!showInfoFields}
          style={{ width: "29%", marginLeft: "2%" }}
        />
      </div>
    </>
  );
}

export default CurpAutogenerated;
