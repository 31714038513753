import axios from "axios";
import { Buffer } from "buffer";
import { jwtDecode } from "jwt-decode";

const belozV2BE = axios.create({
  baseURL: import.meta.env.VITE_APP_BELOZ_BE_V2_ENDPOINT,
  timeout: parseInt(import.meta.env.VITE_APP_BACKEND_TIMEOUT),
});

export const checkBlacklistByFieldService = async (keyType, value) => {
  try {
    const { data } = await belozV2BE.post("/blacklist/active/by-field", {
      keyType,
      value,
    });
    if (data?.data) {
      return {
        canContinue: false,
        reason: data?.data?.reason,
      };
    }

    return {
      canContinue: true,
    };
  } catch (e) {
    console.log("checkBlacklistByFieldService", e);
    return {
      canContinue: false,
    };
  }
};

export const checkIfIsNewUserService = async (curp = null, phone = null) => {
  try {
    const { data } = await belozV2BE.post("/blacklist/is-new-user", {
      curp,
      phone,
    });

    return {
      isNew: data?.data?.isNew,
    };
  } catch (e) {
    console.log("checkIfIsNewUserService", e);
    return {
      isNew: false,
    };
  }
};

export const getCURPDataService = async (curp) => {
  try {
    // Crear una promesa que se rechace después de 15 segundos
    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(
          new Error("La solicitud ha superado el tiempo límite de 15 segundos")
        );
      }, 5000); // 5 segundos expresados en milisegundos
    });

    // Realizar la solicitud HTTP
    const request = belozV2BE.post("/external/get-curp-data", { curp });

    // Utilizar Promise.race() para ver cuál promesa se resuelve o rechaza primero
    const { data } = await Promise.race([request, timeout]);

    // Verificar el éxito y el estatus de la respuesta
    let success = true;
    if (!data?.success || data?.data?.estatus === "ERROR") success = false;

    return {
      success: success,
      data: success ? data?.data : null,
    };
  } catch (error) {
    console.log("getCURPDataService", error.message);
    return {
      success: false,
      data: null,
      error: error.response?.data || error.message,
    };
  }
};

export const getZipCodeDataService = async (zipCode) => {
  try {
    const { data } = await belozV2BE.post(
      `/landing/get-address-info/${zipCode}`,
      {
        provider: "privalia",
      }
    );
    return {
      success: data?.success,
      data: data?.success ? data?.data : null,
    };
  } catch (error) {
    console.log("getZipCodeDataService", error);
    return {
      success: false,
      data: null,
    };
  }
};

export const createNewUserService = async (phone, password) => {
  try {
    const { data } = await belozV2BE.post("/authentication/user/new", {
      phone: {
        countryCode: "+52",
        number: phone.replaceAll(" ", ""),
      },
      password: password.password,
    });

    return {
      success: data.success,
      token: data.data,
    };
  } catch (error) {
    console.log("createNewUserService", error);
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const requestCDCCodeService = async (phone, token) => {
  try {
    const tokenData = jwtDecode(token);

    const { data } = await belozV2BE.post("/auth/credit/otp", {
      userID: tokenData?.id,
      phoneNumber: phone.replaceAll(" ", ""),
      countryCode: "+52",
    });
    return {
      success: data.success,
    };
  } catch (error) {
    console.log("requestCDCCodeService", error);
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const requestCodeServiceV2 = async (phone, type = "whatsapp") => {
  try {
    const { data } = await belozV2BE.post("/auth/code", {
      phone: {
        countryCode: "+52",
        number: phone.replaceAll(" ", ""),
      },
      language: "es",
      type: type,
    });
    return {
      success: data.success,
    };
  } catch (error) {
    console.log("requestCodeServiceV2", error);
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const validateCDCCodeService = async (token, code) => {
  try {
    const tokenData = jwtDecode(token);

    const { data } = await belozV2BE.post("/auth/credit/otp/validate", {
      userID: tokenData?.id,
      code,
    });

    return {
      success: data.success,
      data: data.success ? data?.data?.result : false,
    };
  } catch (error) {
    console.log("validateCDCCodeService", error);
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const validateCodeServiceV2 = async (phone, code) => {
  try {
    const { data } = await belozV2BE.post("/auth/code/validate", {
      belozEmail: `+52${phone}@phone-fix.amiloz.com`,
      code: Buffer.from(code).toString("base64"),
    });

    return {
      success: data?.success,
      data: data?.success ? data?.data : null,
    };
  } catch (error) {
    console.log("validateCodeService", error);
    return {
      success: false,
      error: error.response.data,
    };
  }
};

export const createLandingApplication = async (
  backendToken,
  form,
  marketing_metadata,
  deviceInfo,
  referrer,
  curpFillType
) => {
  try {
    const { data } = await belozV2BE.post(
      "/landing/create-application-only",
      {
        form,
        marketing_metadata,
        deviceInfo,
        referrer,
        curpFillType,
      },
      {
        headers: {
          Authorization: `Token ${backendToken}`,
        },
      }
    );
    return {
      success: data?.success,
      applicationId: data?.success ? data?.data?.applicationId : null,
      message: data?.data?.message,
    };
  } catch (e) {
    console.log("createLandingApplication", e);
    return {
      success: false,
    };
  }
};
