import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { validaFormatoCP } from "../../utils/address";
import { toast } from "react-toastify";
import { getZipCodeDataService } from "../../services/application.services";

function AddressAutocomplete({
  formData,
  setFormData,
  handleAddressInfo,
  setShowAddressFields,
}) {
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressComponents, setAddressComponents] = useState(null);
  const [showNeighborhoodSelection, setShowNeighborhoodSelection] =
    useState(false);

  const processZipCode = async (zipCode) => {
    try {
      await getZipCodeInfo(zipCode);
    } catch (error) {
      console.log("processZipCode error", error);
      handleAddressInfo("", "zipCode");
      setLoading(false);
    }
  };

  const getZipCodeInfo = async (zipCode) => {
    try {
      const { success, data } = await getZipCodeDataService(zipCode);
      if (!success) {
        setHasError(true);
        setLoading(false);
        handleAddressInfo("", "zipCode");
        toast.error(
          "No pudimos encontrar tu código postal. Por favor, verifica que sea correcto.",
          {
            position: "top-center",
            theme: "light",
            autoClose: false,
          }
        );
        return;
      }
      setAddressComponents(data);
      setShowNeighborhoodSelection(true);
      setLoading(false);
    } catch (error) {
      handleAddressInfo("", "zipCode");
      console.log("getZipCodeInfo error", error);
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      addressInfo: {
        ...formData?.addressInfo,
        zipCode: formData?.addressInfo?.zipCode,
        neighborhood: event.target.value,
        city: addressComponents?.[0]?.response?.municipio,
        state: addressComponents?.[0]?.response?.estado,
      },
    });
    setShowAddressFields(true);
  };

  useEffect(() => {
    const zipCode = formData?.addressInfo?.zipCode;
    if (formData?.addressInfo?.neighborhood !== "") {
      setShowAddressFields(true);
      setLoading(false);
    } else if (zipCode.length === 5) {
      setLoading(true);
      if (!validaFormatoCP(zipCode)) {
        setHasError(true);
        setLoading(false);
        handleAddressInfo("", "zipCode");
      } else {
        setHasError(false);
        processZipCode(zipCode);
      }
    }
  }, [formData?.addressInfo?.zipCode]);

  return (
    <section>
      <FormControl
        variant="outlined"
        fullWidth
        style={{ marginBottom: "16px", marginTop: "8px" }}
      >
        <InputLabel htmlFor="zipCode">Código Postal</InputLabel>
        <OutlinedInput
          id="zipCode"
          error={hasError}
          value={formData?.addressInfo?.zipCode}
          onChange={(e) => handleAddressInfo(e.target.value, "zipCode")}
          inputProps={{ maxLength: 5 }}
          labelWidth={104}
          disabled={loading}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={formData?.addressInfo?.zipCode.length !== 5}
                onClick={() => getZipCodeInfo(formData?.addressInfo?.zipCode)}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {showNeighborhoodSelection && (
        <TextField
          select
          label="Colonia"
          value={formData?.addressInfo?.neighborhood}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "16px", marginTop: "8px" }}
        >
          {addressComponents?.map((option) => (
            <MenuItem
              key={option?.response?.asentamiento}
              value={option?.response?.asentamiento}
            >
              {option?.response?.asentamiento}
            </MenuItem>
          ))}
        </TextField>
      )}
    </section>
  );
}

export default AddressAutocomplete;
