import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    height: "90vh",
    maxHeight: "90vh",
    minWidth: "33vw",
    maxWidth: "33vw",
    [theme.breakpoints.down("sm")]: {
      minWidth: "90vw",
      maxWidth: "90vw",
      height: "85vh",
      maxHeight: "85vh",
    },
  },
  stepTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stepText: {
    cursor: "pointer",
    color: "#FACE07",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  progressBar: {
    width: "50%",
    height: "30px",
    backgroundColor: "rgba(250, 206, 7, 0.3)",
    border: "1px solid #FACE07",
    borderRadius: "8px",
    position: "relative",
  },
  progressBarInner: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#FACE07",
    borderRadius: "8px",
  },
  progressTextContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100% - 32px)",
    height: "100%",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navButton: {
    backgroundColor: "#FACE07",
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  disabledNavButton: {
    backgroundColor: "#C4C4C4",
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
  },
  contactLink: {
    color: "green",
    fontWeight: 500,
    textDecoration: "none",
  },
  text: {
    lineHeight: 1.25,
  },
  //   Address styles
  root: {
    "& input:valid + fieldset": {
      borderColor: "#FACE07",
      borderWidth: 2,
    },
    "& fieldset": {
      borderColor: "#FACE07",
      borderWidth: 2,
    },
  },
  addressSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  textFieldUppercase: {
    textTransform: "uppercase",
  },
  halfWidthLeft: {
    width: "49%",
  },
  halfWidthRight: {
    width: "49%",
    marginLeft: "2%",
  },
  // Personal information styles
  sectionFlexColumn: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  threeColumnSplit: {
    width: "33%",
  },
  fullWidth: {
    width: "100%",
  },
  subHeader: {
    fontWeight: 500,
  },
  // Password
  // Acceptance
  primaryAction: {
    backgroundColor: "black",
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#FACE07",
    },
  },
  imgCenter: {
    width: "50%",
    alignSelf: "center",
  },
  textCenter: {
    alignSelf: "center",
  },
  fontWeightMedium: {
    fontWeight: 500,
  },
  scrollableBox: {
    border: "1px solid #F2F2F7",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    height: "25vh",
    overflowY: "scroll",
    overflowX: "none",
    backgroundColor: "#F2F2F7",
  },
  // Evaluation
  evaluation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  textBlock: {
    alignSelf: "flex-start",
    lineHeight: 1,
    fontWeight: 500,
    textAlign: "center",
  },
  imageHalfWidth: {
    width: "50%",
  },
}));

export default useStyles;
