import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
  secondaryAction: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contentBox: {
    maxWidth: theme.breakpoints.values["md"],
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values["lg"] / 2,
      maxHeight: 512,
      marginRight: 0,
      textAlign: "left",
    },
  },
  img: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "cover",
    height: 512,
    width: "100%",
  },
}));

export default function LatestEntry({ latestBlog }) {
  const classes = useStyles();
  const blog = latestBlog[0];

  return (
    <section>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Box className={classes.contentBox}>
            <Container>
              <Typography variant="h3" component="h2" gutterBottom={true}>
                {blog?.title}
              </Typography>
              <Typography variant="h5" color="textSecondary" paragraph={true}>
                {blog?.intro}
              </Typography>
              <Box mt={4}>
                <Button variant="contained" href={`/article?idArticle=${blog?.id}`} fullWidth className={classes.secondaryAction}>
                  Leer blog 
                </Button>
              </Box>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box position="relative" height={512}>
            <img className={classes.img} src={blog?.img} alt="" />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
