import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import "react-phone-input-2/lib/material.css";
import {
  checkIfIsNewUserService,
  createNewUserService,
  requestCDCCodeService,
  requestCodeServiceV2,
} from "../../services/application.services";
import { toast } from "react-toastify";
import { useAnalytics } from "../../hooks/useAnalytics";

function UserPhoneInput({
  phone,
  setPhone,
  setCodeSent,
  password,
  setAmilozBEToken,
}) {
  const analytics = useAnalytics();
  const [disabled, setDisabled] = useState(false);

  const handleSendCode = async () => {
    try {
      analytics.sendEvent({
        event: "AplicaStep2.3",
        event_category: "ButtonClick",
      });
      // TODO: Enable password
      // const { success: userCreatedSucceess, token } =
      //   await createNewUserService(phone.slice(2), password);
      // if (userCreatedSucceess) {
      //   setAmilozBEToken(token);
      // }

      const { success } = await requestCodeServiceV2(phone.slice(2), 'cdc');
      if (success) {
        setCodeSent(true);
      } else {
        setPhone("");
        setDisabled(true);
      }
    } catch (error) {
      console.log("handleSendCode error", error);
    }
  };

  useEffect(() => {
    async function checkIfNewUser(tempPhone) {
      const { isNew } = await checkIfIsNewUserService(null, tempPhone);
      if (!isNew) {
        setPhone("52");
        toast.warning(
          "Ya tienes un usuario con nosotros. Por favor, revisa en tu app tus ofertas disponibles. Puedes dar click aquí para abrirla.",
          {
            position: "top-center",
            theme: "light",
            autoClose: false,
            onClick: () =>
              (window.location.href = "https://appbeloz.page.link/download"),
          }
        );
        return;
      }
    }

    if (phone.length === 12) {
      let tempPhone = phone;
      checkIfNewUser(tempPhone);
    }
  }, [phone]);

  return (
    <section
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      <section style={{ width: "300px" }}>
        <PhoneInput
          country={"mx"}
          specialLabel="Teléfono celular"
          onlyCountries={["mx"]}
          localization={es}
          value={phone}
          onChange={(phone) => setPhone(phone)}
          style={{ width: "auto" }}
        />
        <Button
          fullWidth
          style={{
            backgroundColor: phone.length !== 12 ? "lightgray" : "#25D366",
            color: "white",
            margin: "16px 0px",
            height: "48px",
          }}
          onClick={handleSendCode}
          disabled={phone.length !== 12 || disabled}
        >
          Enviar código por Whatsapp
        </Button>
      </section>
    </section>
  );
}

export default UserPhoneInput;
