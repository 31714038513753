import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default function Benefits() {
  return (
    <section style={{ color: "black", backgroundColor: "#FACE07" }}>
      <Container maxWidth="lg">
        <Box py={6}>
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/1.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  100% Digital
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Sin filas, resuelve todo desde tu aplicación en minutos.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/2.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  Renovable
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Al pagar a tiempo tu línea de crédito se renueva.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/3.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  Oferta inicial
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Comienza con una oferta inicial con 0% de interés.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/4.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  Flexible
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Personaliza los montos y plazos de tu préstamo a tu
                  preferencia.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/5.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  Grupal o individual
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Entre más grande el grupo, mayores los beneficios en montos e
                  interés.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box textAlign="center" mb={4}>
                <Box mb={2}>
                  <img
                    src="./images/benefits/6.png"
                    style={{ width: "25%" }}
                  />
                </Box>
                <Typography variant="h6" component="h6" color="inherit">
                  Seguro
                </Typography>
                <Typography variant="subtitle1" component="p" color="inherit">
                  Transparencia en todo el proceso.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
