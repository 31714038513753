export const getAllQueryParams = (search) => {
  const params = new URLSearchParams(search);
  let queryParams = {};

  for (let param of params.entries()) {
    queryParams[param[0]] = param[1];
  }

  return queryParams;
};

export const getBaseUrl = (url) => {
  const regex = /^(https?:\/\/[^\s/$.?#].[^\s]*)\/[^/?#]*/i;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    console.error("Invalid URL:", url);
    return null;
  }
};

export const generateDynamicLink = (url) => {
  const baseUrl = getBaseUrl(url);
  const encodedLink = encodeURIComponent(url);

  return `${baseUrl}?link=${encodedLink}&apn=com.beloz.app&ibi=com.beloz.main&isi=1663877259`;
};

export const defaultUTMParams =
  "channel=organic&source=wom&account=na&landing=mexico&campaign=buscador";
