import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    height: 512
  },
}));

export default function Help(props) {
  const classes = useStyles();

  const content = {
    "header-p1": "¿Alguna duda?",
    description: "Contáctanos y te apoyaremos en cualquier momento",
    "primary-action": "Action",
    image: "./images/help/friend.png",
    ...props.content,
  };

  return (
    <section style={{ backgroundColor: "rgb(255, 253, 235)" }}>
      <Container maxWidth="lg">
        <Box py={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%">
                <Box my="auto">
                  <Typography
                    variant="h3"
                    component="h2"
                    gutterBottom={true}
                    style={{ fontWeight: 700 }}
                  >
                    {content["header-p1"]}
                  </Typography>
                  <Typography variant="h4" paragraph={true}>
                    {content["description"]}
                  </Typography>
                  <Box mt={3} mb={3}>
                    <Link variant="h5" href="mailto:soporte@amiloz.com" style={{ color: 'black', textDecoration: 'underline', fontWeight: 500}}>
                        soporte@amiloz.com
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} style={{ borderRadius: 16 }}>
              <Card>
                <CardMedia image={content["image"]} className={classes.card} />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
