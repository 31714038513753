import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Link } from "@material-ui/core";
import { useDynamicLink } from "../hooks/useDynamicLink";
import { useAnalytics } from "../hooks/useAnalytics";

const useStyles = makeStyles((theme) => ({
  primaryAction: {
    backgroundColor: "black",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
    "&:hover": {
      backgroundColor: "#FACE07",
    },
  },
  card: {
    height: 512,
    borderRadius: 8,
  },
  logo: {
    height: 36,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

function Banner({ handleOpen }) {
  const classes = useStyles();
  const analytics = useAnalytics();
  const downloadAppDynamicLink = useDynamicLink();

  return (
    <section>
      <Container maxWidth="lg">
        <Box py={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box display="flex" height="100%">
                <Box my="auto">
                  <Typography
                    variant="h3"
                    component="h1"
                    gutterBottom={true}
                    style={{ fontWeight: 700 }}
                  >
                    Préstamos Digitales Rápidos, Seguros y con 0% de Interés en Minutos	
                  </Typography>
                  <Typography
                    variant="h5"
                    component="h2"
                    color="textSecondary"
                    paragraph={true}
                  >
                    De $750 a $5,000 MXN al instante. Crece financieramente y mejora tu historial crediticio.	
                  </Typography>
                  <Button
                    id="aplicar-banner"
                    variant="contained"
                    color="primary"
                    className={classes.primaryAction}
                    onClick={handleOpen}
                  >
                    Aplicar
                  </Button>
                  <Typography variant="h6">Descarga la aplicación</Typography>
                  <Box
                    pt={2}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                  >
                    <Link
                      href={downloadAppDynamicLink.android}
                      onClick={() =>
                        analytics.sendEvent({
                          event: "Subscribe",
                          event_category: "DownloadApp",
                        })
                      }
                      target="_blank"
                    >
                      <img
                        src="./images/download/playstore.png"
                        alt="Google Play Store"
                        className={classes.logo}
                      />
                    </Link>
                    <Link
                      href={downloadAppDynamicLink.ios}
                      onClick={() =>
                        analytics.sendEvent({
                          event: "Subscribe",
                          event_category: "DownloadApp",
                        })
                      }
                      target="_blank"
                    >
                      <img
                        src="./images/download/appstore.png"
                        alt="App Store"
                        className={classes.logo}
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className={classes.card}
                  src="./images/banner/banner.png"
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}

export default Banner;
