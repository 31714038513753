import axios from "axios";

const experimentsBE = axios.create({
  baseURL: import.meta.env.VITE_APP_EXPERIMENTS_ENDPOINT,
  timeout: parseInt(import.meta.env.VITE_APP_BACKEND_TIMEOUT),
});

export const getExperimentEvaluationService = async (
  experiment_name,
  entity_id,
  defaultValue
) => {
  try {
    const { data } = await experimentsBE.post("/v1/experiment/evaluate", {
      experiment_name,
      entity_id,
    });
    if (!data?.success) {
      return defaultValue;
    }
    return data?.data?.treatment;
  } catch (error) {
    console.log("error at getExperimentEvaluationService", error.response);
    return defaultValue;
  }
};
