import { useLocation } from "react-router-dom";
import { defaultUTMParams, generateDynamicLink } from "../utils/url";
import { isMobile } from "react-device-detect";

const APP_STORE_DIRECT_LINK =
  "https://apps.apple.com/mx/app/beloz/id1663877259";
const PLAY_STORE_DIRECT_LINK =
  "https://play.google.com/store/apps/details?id=com.beloz.app";

export const useDynamicLink = () => {
  const dynamicLinkBaseURL = "https://mexicobeloz.page.link/download";
  const queryParams = useLocation().search || `?${defaultUTMParams}`;
  const dynamicLink = generateDynamicLink(
    `${dynamicLinkBaseURL}${queryParams}`
  );

  return {
    ios: isMobile ? dynamicLink : APP_STORE_DIRECT_LINK,
    android: isMobile ? dynamicLink : PLAY_STORE_DIRECT_LINK,
  };
};
