import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import { Box, Button } from "@material-ui/core";
import { useDynamicLink } from "../hooks/useDynamicLink";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: 70,
  },
  brand: {
    lineHeight: 1,
    marginRight: "auto",
  },
  link: {
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  primaryAction: {
    backgroundColor: "#FACE07",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    "&:hover": {
      backgroundColor: "black",
      boxShadow: "0px 0px 5px #FACE07",
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  iconWrapper: {
    minWidth: 40,
  },
  icon: {
    color: theme.palette.text.hint,
  },
  drawerContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 300,
  },
  footerNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
  logo: {
    height: 36,
    [theme.breakpoints.down("xs")]: {
      height: 24,
    },
  },
}));

export default function Navigation({ isHomePage = false, handleOpen }) {
  const classes = useStyles();
  const downloadAppDynamicLink = useDynamicLink();

  const content = {
    brand: { image: "./images/navbar/logo.png" },
    "primary-action": "Action",
  };

  let brand;

  if (content.brand.image) {
    brand = (
      <img src={content.brand.image} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }

  return (
    <AppBar
      position="sticky"
      color="inherit"
      style={{ backgroundColor: "black" }}
    >
      <Toolbar className={classes.toolbar}>
        <Link
          href="/"
          color="primary"
          underline="none"
          variant="h5"
          className={classes.brand}
        >
          {brand}
        </Link>
        <Box component="nav" ml="auto" className={classes.footerNav}>
          {isHomePage ? (
            <>
              <Button variant="text" href="/blog" style={{ color: "white" }}>
                Blog
              </Button>
              <Button
                id="aplicar-navbar"
                variant="contained"
                color="primary"
                size="small"
                className={classes.primaryAction}
                onClick={handleOpen}
              >
                Aplicar
              </Button>
            </>
          ) : (
            <>
              <Link
                href={downloadAppDynamicLink.android}
                onClick={() =>
                  analytics.sendEvent({
                    event: "Subscribe",
                    event_category: "DownloadApp",
                  })
                }
                target="_blank"
              >
                <img
                  src="./images/download/playstore.png"
                  alt="Google Play Store"
                  className={classes.logo}
                />
              </Link>
              <Link
                href={downloadAppDynamicLink.ios}
                onClick={() =>
                  analytics.sendEvent({
                    event: "Subscribe",
                    event_category: "DownloadApp",
                  })
                }
                target="_blank"
              >
                <img
                  src="./images/download/appstore.png"
                  alt="App Store"
                  className={classes.logo}
                />
              </Link>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
