import ReactDOM from "react-dom/client";
import App from "./App.jsx";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://0f25edfedd516c31135a281f43e4d884@o4507173317967872.ingest.us.sentry.io/4507173319344128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Captura el 100% de las transacciones para monitoreo detallado
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "www.belozmexico.com.mx",
    /^https:\/\/www\.belozmexico\.com\.mx\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // Esto configura la tasa de muestra al 10%. Considera ajustarla al 100% durante el desarrollo y luego reducirla en producción.
  replaysOnErrorSampleRate: 1.0, // Cambia la tasa de muestra al 100% cuando monitorees sesiones donde ocurren errores.
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <App />
    <ToastContainer />
  </>
);
