import { useEffect, useState } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import ReactCodeInput from "react-code-input";
import { validateCDCCodeService, validateCodeServiceV2 } from "../../services/application.services";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  codeInput: {
    "& > input": {
      fontFamily: "monospace",
      borderRadius: "6px",
      border: "1px solid rgb(238, 211, 215)",
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px 0px",
      margin: "4px",
      paddingLeft: "8px",
      width: "48px!important",
      height: "48px!important",
      fontSize: "32px",
      boxSizing: "border-box",
      color: "rgb(185, 74, 72)",
      backgroundColor: "rgb(242, 222, 222)",
      textAlign: "center",
    },
  },
}));

const CODE_LENGTH = 4;
function CodeInput({
  phone,
  setCodeSent,
  setPhone,
  amilozBEToken,
  setAmilozBEToken,
  isCodeValid,
  setIsCodeValid
}) {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const checkCode = async () => {
    try {
      setLoading(true);
      const { success, data } = await validateCodeServiceV2(
        phone.slice(2),
        code
      );
      if (!success) {
        toast.warning(
          "El código ingresado no es válido. Por favor, intenta nuevamente.",
          {
            position: "top-center",
            theme: "light",
            autoClose: false,
          }
        );
        setCode("");
        setLoading(false);
      } else {
        setAmilozBEToken(data.token);
        setIsCodeValid(true);
      }
    } catch (error) {
      console.log("checkCode error", error);
    }
  };

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      checkCode();
    }
  }, [code]);

  return (
    <section
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        marginTop: "8px",
        marginBottom: "8px",
      }}
    >
      {!isCodeValid && (
        <>
          <section
            style={{
              alignSelf: "flex-start",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              Ingresa el código que envíamos al teléfono celular:
            </Typography>
            <Button
              onClick={() => {
                setPhone("52");
                setCodeSent(false);
              }}
              endIcon={<Edit />}
            >
              {phone.slice(2)}
            </Button>
          </section>
          <section
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ReactCodeInput
              isValid={isCodeValid}
              fields={CODE_LENGTH}
              onChange={setCode}
              value={code}
              disabled={loading}
              type="number"
              className={classes.codeInput}
            />
          </section>
        </>
      )}
    </section>
  );
}

export default CodeInput;
