import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Link } from "@material-ui/core";
import { useDynamicLink } from "../hooks/useDynamicLink";
import { useAnalytics } from "../hooks/useAnalytics";

const useStyles = makeStyles((theme) => ({
  contentBox: {
    maxWidth: theme.breakpoints.values["md"],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values["lg"] / 2,
      maxHeight: 624,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: 0,
      textAlign: "left",
    },
  },
  footerNav: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  button: {
    height: 36,
    backgroundColor: "#FACE07",
    "&:hover": {
      backgroundColor: "black",
      boxShadow: "0px 0px 5px #FACE07",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  logo: {
    height: 36,
  },
  img: {
    position: "absolute",
    top: 16,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "contain",
    height: 580,
    width: "100%",
  },
}));

export default function Process({ handleOpen }) {
  const classes = useStyles();
  const analytics = useAnalytics();
  const downloadAppDynamicLink = useDynamicLink();

  return (
    <section>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Box className={classes.contentBox}>
            <Container>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom={true}
                style={{ fontWeight: 700 }}
              >
                Proceso de aplicación
              </Typography>
              <section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      padding: "6px 16px",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      1
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Descarga la app
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Disponible para android y iPhone.
                    </Typography>
                    <Box
                      component="nav"
                      ml="auto"
                      className={classes.footerNav}
                    >
                      <Button
                        id="aplicar-process"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleOpen}
                      >
                        Aplicar
                      </Button>
                      <Link
                        href={downloadAppDynamicLink.android}
                        onClick={() =>
                          analytics.sendEvent({
                            event: "Subscribe",
                            event_category: "DownloadApp",
                          })
                        }
                        target="_blank"
                      >
                        <img
                          src="./images/download/playstore.png"
                          alt="Google Play Store"
                          className={classes.logo}
                        />
                      </Link>
                      <Link
                        href={downloadAppDynamicLink.ios}
                        onClick={() =>
                          analytics.sendEvent({
                            event: "Subscribe",
                            event_category: "DownloadApp",
                          })
                        }
                        target="_blank"
                      >
                        <img
                          src="./images/download/appstore.png"
                          alt="App Store"
                          className={classes.logo}
                        />
                      </Link>
                    </Box>
                  </div>
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      padding: "6px 16px",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      2
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Completa tus datos
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Verificaremos tu información rápidamente.
                    </Typography>
                  </div>
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      padding: "6px 16px",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      3
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Pide tu financiamiento
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Selecciona el monto, plazo, número de pagos e invita
                      miembros para hacerlo grupal.
                    </Typography>
                  </div>
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      padding: "6px 16px",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      4
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Recibe tu dinero
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Directo en tu cuenta bancaria.
                    </Typography>
                  </div>
                </section>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "black",
                      padding: "6px 16px",
                      borderRadius: "32px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        color: "white",
                      }}
                    >
                      5
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Renueva tu línea de crédito
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{ fontWeight: 500, textAlign: "left" }}
                    >
                      Si pagas a tiempo, tu crédito se renueva al monto y plazo
                      de tu preferencia.
                    </Typography>
                  </div>
                </section>
              </section>
            </Container>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box position="relative" height={592}>
            <img
              className={classes.img}
              src="./images/download/phone.png"
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
